import React, { Component } from 'react';
import Top from '../Components/Top'

class MainPage extends Component {
    render() {
        return (
            <div>
                <Top />
            </div>
        );
    }
}

export default MainPage;