
import React, { Component, useRef } from 'react';
//import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useState } from 'react';
import { read, utils } from 'xlsx';
const imQr = 'https://parfumerka.md/wp-content/uploads/catalog_img/logo.jpg'

let arrayHeader = []
let arrayValues = []
let dim = []
let xlsxImportName = 'Michel_Katana_Bags'
let ramas = ''
let newString = 0

function tdPrint(data, imKey1, imKey2) {
    let i = -1
    const lengthX = data.length
    let im1
    // im1 = 'https://parfumerka.md/wp-content/uploads/catalog_img/' + imKey2 + '.jpg'
    return (
        data.map(x => {
            i++
            im1 = 'https://parfumerka.md/wp-content/uploads/catalog_img/' + imKey1 + '.jpg'
            // if (imKey1 === null) {  im1 = 'https://parfumerka.md/wp-content/uploads/catalog_img/' + imKey2 + '.jpg' }
            if (i === 9) { im1 = 'https://parfumerka.md/wp-content/uploads/catalog_img/' + imKey2 + '.jpg' }
            if (imKey1 === undefined) { im1 = 'https://parfumerka.md/wp-content/uploads/catalog_img/' + imKey2 + '.jpg' }

            if (i === 1) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", color: "#003AB3", fontWeight: 'Bold', fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 2) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", fontWeight: 'Bold', verticalAlign: "middle" }}>{x}</td>) }
            //if (i === 3) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 4) { return (<td style={{ border: "1px solid #000", width: '150px', alignContent: "center", verticalAlign: "middle", margin: "1px 1px 0px 0px" }}><img src={im1} /> </td>) }
            if (i === 6) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", color: "#003AB3", fontWeight: 'Bold', fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 7) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", fontWeight: 'Bold', verticalAlign: "middle" }}>{x}</td>) }
            // if (i === 8) { return (<td style={{ border: "1px solid #000", width: '10px', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>{x}</td>) }
            if (i === 9) { return (<td style={{ border: "1px solid #000", width: '150px', alignContent: "center", verticalAlign: "middle", margin: "1px 1px 0px 0px" }}><img src={im1} /> </td>) }
        })
    )
}

function XlsLoad() {
    const [data, setdata] = useState([])
    const readfile = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    arrayHeader.push(Object.keys(rows[0]))
                    ramas = 'Ramas'
                    arrayValues = rows
                    setdata(rows);
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
    const tableRef = useRef(null);
    {
        const inStockOnly = document.getElementById('inStockOnly') !== null ? document.getElementById('inStockOnly').checked : null
        dim.push('Наименование', 'Бренд', 'Артикул', 'Картинка 1', 'Наименование2', 'Бренд2', 'Артикул2', 'Картинка 2')
        const header = dim !== undefined ? Array.from(new Set(dim)) : null

        let newArray = []
        let new1 = []

        for (let key in arrayValues) { // строки 1,2,3...


            new1[newString + 0] = arrayValues[key].Категория
            new1[newString + 1] = arrayValues[key]['Имя']
            new1[newString + 2] = arrayValues[key]['Значения атрибутов 3']
            new1[newString + 3] = arrayValues[key].Артикул
            new1[newString + 4] = 'photo1'


            const articul = arrayValues[key].Артикул !== undefined ? arrayValues[key].Артикул.replace('/', '_') : ''
            //console.log(new1)
            newString = newString + 5
            if (newString === 10) { newArray.push(new1); console.log(new1) }
            if (newString === 10) { new1 = [] }

            if (newString === 10) { newString = 0 }
        }

        for (let key in newArray) {
            console.log(key)
            console.log(newArray[key])
        }


        let i = -1
        return (
            <>
                <fieldset class='p-1 border'> <b>Шаг 2. Выберите файл Базы Эксель</b><input class='mx-2' type='file' onChange={readfile} accept='.xlsx' /></fieldset>





                <table ref={tableRef} style={{ margin: '50px ' }}>
                    <thead>
                        <tr style={{ fontSize: '16pt' }}>

                        </tr>
                        <tr style={{ fontWeight: 'Bold', wordWrap: 'break-word', textAlign: "center", fontSize: "9pt", verticalAlign: "middle" }}>
                            {header.map(item => {
                                i++
                                if (i === 0) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>Наименование</td>) }
                                if (i === 1) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>Бренд</td>) }
                                //if (i === 2) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '75px' }}>Артикул</td>) }
                                if (i === 3) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '150px' }}>Фото</td>) }
                                if (i === 4) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>Наименование</td>) }
                                if (i === 5) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '60px' }}>Бренд</td>) }
                                // if (i === 6) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '75px' }}>Артикул</td>) }
                                if (i === 7) { return (<td style={{ border: "1px solid #000", backgroundColor: "#FFFF99", width: '150px' }}>Фото</td>) }

                            })}
                        </tr>
                    </thead>
                    <tbody>

                        {newArray.map(x => {
                            //const imKey1 = x[3] !== undefined ? x[3].replace('/', '_') + '.jpg' : null
                            if (x[1] === undefined && x[0] !== undefined) {
                                if (x[0] !== 'Общий итог') {
                                    return (<tr><td colSpan={5} style={{ border: "1px solid #000", height: '20px', fontSize: '11pt', fontWeight: 'bold', whiteSpace: "nowrap" }}>{x[0].replace(' Итог', '')}</td></tr>)
                                }
                            } else console.log("x[3]=")
                                   console.log(x[3])
                                   console.log("x[8]=")
                                   console.log(x[8])
                              
                                   return (
                                    <tr style={{ height: '132px' }}>{tdPrint(x, x[3], x[8])}</tr>
                                )
                        })}
                    </tbody>
                </table>
            </>
        )
    }
}

class Parfum extends Component {
    render() {
        return (
            <>

                <XlsLoad />

            </>
        )
    }
}

export default Parfum;